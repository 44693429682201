import React  from 'react'
import { useTranslation } from 'react-i18next';
import { InlineWidget } from "react-calendly";

// Components
import MainLayout from '../../components/main-layout';
import HeaderSection from './header-section';

const Demo = (props) => {
    const { t } = useTranslation(),
        { pageContext } = props;

    return (
        <MainLayout
            pageContext={pageContext}
        >
            <HeaderSection key="demo-section-1" {...props} t={t} />
            <div className="container-fluid pt30 mb20">
                <div className="row">
                    <div className="col-12 mb20">
                        <InlineWidget url="https://calendly.com/biuwer/biuwer-demo-30-min" style={{ minHeight: 700 }} />
                    </div>
                </div>
            </div>
        </MainLayout>
    )
};

export default Demo;